import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AlertDialogSlide from 'common/components/dialog/generalDialog';
import { CaptchaInput, SelectLabel } from 'common/components/fields/fields';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import { cpfMask, dateMask, phoneMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { FullButton } from '../../../common/components/buttons/fullButton';
import { InputLabelOutlined } from '../../../common/components/fields/fields';
import LoadingProgress from '../../../common/components/progress/loading';
import {
  ASTCU_STATION_ID,
  BASE_S3,
  COMPANY_COLORS,
  COMPANY_ID,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  COMPANY_STATION_ID,
  GENERAL_TEXT_COLOR,
  IS_ASTCU,
  IS_BRB_FLA,
  IS_CAIXA,
  IS_SINDILEGIS,
  SINDILEGIS_STATION_ID,
} from '../../../config/consts';
import { getStationData } from '../../../modules/website/websiteActions';
import {
  participantCredentialsValidations,
  selectConfirmationMethod,
  showModal,
  signup,
  signupAdmin,
  validateMGM,
} from '../authActions';
import PrivacyPolicy from '../policies/privacyPolicy';
import UseTerms from '../policies/usePolicy';
import PassStepForm from './passwordStepForm';
import './signupStepForm.css';
import states from 'helpers/states.json';

const GreenCheckbox = withStyles({
  root: {
    color: GENERAL_TEXT_COLOR,
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

states.sort(function (a, b) {
  if (a.sigla < b.sigla) {
    return -1;
  }
  if (a.sigla > b.sigla) {
    return 1;
  }
  return 0;
});

class SignupStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepBool: false,
      visible: false,
      stepForm: props.history.location?.state?.stepForm || 0,
      private: false,
      privacy: false,
      useTerms: false,
      checked: false,
      covidChecked: false,
      rulesChecked: false,
      affiliatedInfo: props.history.location?.state?.affiliatedInfo || {},
      showPassword: false,
    };

    if (props.match.params?.code_mgm) {
      props.validateMGM(props.match.params?.code_mgm);
    }
  }

  componentDidMount() {
    // if (!this.props.website.stationData.address) {
    this.props.getStationData(COMPANY_STATION_ID);
    // }
  }

  cleanup = (affiliatedInfo) => {
    console.log(COMPANY_STATION_ID);
    if (this.state.stepForm === 0) {
      if (COMPANY_STATION_ID === SINDILEGIS_STATION_ID) {
        this.setState({
          stepForm: 1,
          affiliatedInfo: {
            email: affiliatedInfo.email,
            tel: affiliatedInfo.celular,
          },
        });
      } else if (COMPANY_STATION_ID === ASTCU_STATION_ID) {
        if (affiliatedInfo.isFiliado) {
          return this.setState({
            stepForm: 1,
            affiliatedInfo: {
              email: affiliatedInfo.email,
              tel: affiliatedInfo.celular,
            },
          });
        }
        return this.setState({ stepForm: 1 });
      } else if (IS_BRB_FLA) {
        this.props.history.push('/login');
      } else {
        this.setState({ stepForm: 1 });
      }

      // Acontece quando usuário faz login e não confirmou o email
    } else if (this.state.stepForm === 2) {
      this.props.history.push({
        pathname: `/confirmacao/${this.state.affiliatedInfo.email}`,
        state: { affiliatedInfo },
      });
    }
  };

  selectConfirmationMethod = (type) => {
    const newValues = {
      station_id: COMPANY_STATION_ID,
      user_id: this.state.affiliatedInfo.id,
      type,
      email: this.state.affiliatedInfo.email,
      tel: this.state.affiliatedInfo?.tel,
      id: this.state.affiliatedInfo.id,
    };
    return this.props.selectConfirmationMethod(newValues, this.cleanup);
  };

  onSubmit = (values) => {
    let newValues = {};

    if (this.state.stepForm === 0) {
      if (IS_BRB_FLA) {
        newValues = {
          ...values,
          station_id: COMPANY_STATION_ID,
          establishment_id: COMPANY_ID,
        };
      } else {
        newValues = {
          ...values,
          station_id: COMPANY_STATION_ID,
          establishment_id: COMPANY_ID,
        };
      }

      this.props.participantCredentialsValidations(newValues, this.cleanup);
    }
  };

  handleClick = () => {
    this.setState({ stepBool: false });
  };

  handleModal = () => {
    this.setState({ visible: true });
  };

  handleHide = () => {
    this.props.showModal();
  };

  handleGoBack = () => {
    this.setState({ stepBool: true });
  };

  onHide = () => {
    this.setState({ visible: false });
  };

  handleCheckBox = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  handleCovidCheckbox = () => {
    this.setState({
      covidChecked: !this.state.covidChecked,
    });
  };
  handleRulesChecked = () => {
    this.setState({
      rulesChecked: !this.state.rulesChecked,
    });
  };

  setStepForm = (step) => {
    this.setState({ stepForm: step });
  };

  setAffiliatedInfo = (affiliatedInfo) => {
    this.setState({ affiliatedInfo });
  };

  renderHiddenPhone = (tel) => {
    if (!tel) return;
    const DDD = `(${tel[0]}${tel[1]})`;
    const number = `9.####-${tel[7]}${tel[8]}${tel[9]}${tel[10]}`;
    const newPhone = `${DDD} ${number}`;

    return newPhone;
  };

  renderHiddenEmail = (email) => {
    const splitedEmail = email.split('@');
    const dotComEmailPart = splitedEmail[1].split('.');

    const newEmail = `${email[0]}${email[1]}${email[2]}${email[3]}${email[4]}###@${splitedEmail[1][0]}${splitedEmail[1][1]}${splitedEmail[1][2]}${splitedEmail[1][3]}${splitedEmail[1][4]}###.${dotComEmailPart[1]}`;

    return newEmail;
  };

  renderEyeShowPassword = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
        >
          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  render() {
    const { website } = COMPANY_COLORS;
    const statesList = states.map((item, index) => ({
      value: item.sigla,
      label: item.nome,
    }));
    const rulesUrl = IS_BRB_FLA
      ? 'https://s3.amazonaws.com/fermento.app.files/files/regulamento.pdf'
      : '';

    if (this.props.auth.loadingMGM) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <LoadingProgress />
        </div>
      );
    }
    return (
      <div
        className="d-flex justify-content-center"
        style={{
          background: `url(${BASE_S3}/${website.header.background_auth_station})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="col-sm-8 col-md-6 col-lg-4 align-self-center py-5">
          <div
            style={{
              background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              borderRadius: 4,
            }}
          >
            <div className="row justify-content-center">
              <div className="col-7 d-flex justify-content-center py-3">
                <a href="/">
                  <img
                    className
                    src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                    alt="Logo"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      maxHeight: 85,
                    }}
                  />
                </a>
              </div>
            </div>
            {this.state.stepForm === 0 ? (
              <div>
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="row justify-content-center my-2">
                      <div className="col-10">
                        <Typography variant="h6" className="text-center">
                          {this.props.match.params?.code_mgm
                            ? 'Cadastre-se agora e ganhe ' +
                              this.props.auth.mgm.memberGetMemberRule
                                .points_invited +
                              'pontos'
                            : IS_BRB_FLA
                            ? 'Cadastre para participar!'
                            : 'Crie seu acesso!'}
                        </Typography>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <form
                        className="col-12"
                        onSubmit={this.props.handleSubmit((v) =>
                          this.onSubmit(v)
                        )}
                        noValidate
                      >
                        {IS_BRB_FLA && (
                          <div className="row justify-content-center mb-1">
                            <div className="col-10 my-2">
                              <Typography variant="h6">
                                Dados de participação
                              </Typography>
                            </div>
                          </div>
                        )}
                        {COMPANY_STATION_ID === SINDILEGIS_STATION_ID ? null : (
                          <div className="row justify-content-center mb-1">
                            <div className="col-10 my-2">
                              <Field
                                component={InputLabelOutlined}
                                label="E-mail"
                                name="email"
                                validate={[
                                  FORM_RULES.required,
                                  FORM_RULES.email,
                                ]}
                                helperText="Digite seu e-mail"
                                normalize={handleWhiteSpaces}
                              />
                            </div>
                          </div>
                        )}

                        {(!this.props.website.stationData.public &&
                          !IS_CAIXA) ||
                        COMPANY_STATION_ID === SINDILEGIS_STATION_ID ||
                        COMPANY_STATION_ID === ASTCU_STATION_ID ? (
                          <div className="row justify-content-center mb-2">
                            <div className="col-10 mt-2">
                              <Field
                                component={InputLabelOutlined}
                                type="tel"
                                label="CPF"
                                helperText="Digite seu CPF"
                                name="cpf"
                                validate={[FORM_RULES.required]}
                                {...cpfMask}
                              />
                            </div>
                          </div>
                        ) : null}
                        {IS_BRB_FLA ? (
                          <>
                            <div className="row justify-content-center mb-2">
                              <div className="col-10 mt-2">
                                <Field
                                  component={InputLabelOutlined}
                                  label="Nome Completo"
                                  name="name"
                                  validate={[FORM_RULES.required]}
                                  helperText="Digite seu nome completo"
                                />
                              </div>
                              <div className="col-10 mt-2">
                                <Field
                                  component={InputLabelOutlined}
                                  type="tel"
                                  label="CPF"
                                  helperText="Digite seu CPF"
                                  name="cpf"
                                  validate={[
                                    FORM_RULES.required,
                                    FORM_RULES.cpf,
                                  ]}
                                  {...cpfMask}
                                />
                              </div>

                              <div className="col-10 mt-3">
                                <Field
                                  component={InputLabelOutlined}
                                  label="Telefone"
                                  type="tel"
                                  inputlabelprops={{
                                    shrink: true,
                                  }}
                                  name="tel"
                                  validate={[FORM_RULES.required]}
                                  helperText="Digite seu telefone"
                                  {...phoneMask}
                                />
                              </div>
                              <div className="col-10 mt-3">
                                <Field
                                  component={InputLabelOutlined}
                                  type="tel"
                                  label="Data de nascimento"
                                  name="birthday"
                                  validate={[
                                    FORM_RULES.required,
                                    FORM_RULES.date,
                                    FORM_RULES.age18,
                                  ]}
                                  {...dateMask}
                                />
                              </div>
                              <div className="col-10 mt-3">
                                <Field
                                  component={SelectLabel}
                                  type="text"
                                  label={`Estado de residência`}
                                  name={`state`}
                                  options={statesList}
                                  validate={[FORM_RULES.required]}
                                />
                              </div>
                              <div className="col-10 mt-3">
                                <Field
                                  component={InputLabelOutlined}
                                  label="Cidade"
                                  type="text"
                                  name="city"
                                  validate={[FORM_RULES.required]}
                                />
                              </div>
                            </div>
                            <div className="row justify-content-center mb-2">
                              <div className="col-10 mt-2">
                                <Typography variant="h6">
                                  Seus dados de acesso
                                </Typography>
                              </div>
                              <div className="col-10 mt-2">
                                <Field
                                  component={InputLabelOutlined}
                                  type={
                                    this.state.showPassword
                                      ? 'text'
                                      : 'password'
                                  }
                                  label="Senha"
                                  name="password"
                                  endAdornment={this.renderEyeShowPassword()}
                                  validate={[
                                    FORM_RULES.required,
                                    FORM_RULES.password,
                                  ]}
                                  helperText="Senha precisa ter: uma letra maiúscula, uma letra minúscula, um número e tamanho entre 8 - 20."
                                />
                              </div>
                            </div>
                            <div className="row justify-content-center mb-2">
                              <div className="col-10 mt-2">
                                <Field
                                  component={InputLabelOutlined}
                                  type={
                                    this.state.showPassword
                                      ? 'text'
                                      : 'password'
                                  }
                                  label="Confirme sua senha"
                                  name="password_confirmation"
                                  endAdornment={this.renderEyeShowPassword()}
                                  validate={[
                                    FORM_RULES.required,
                                    FORM_RULES.password,
                                  ]}
                                  helperText="Digite a mesma senha que digitou acima"
                                />
                              </div>
                            </div>
                            <div className="container">
                              <div className="row justify-content-center">
                                <div
                                  className="col-10 my-2"
                                  style={{ padding: 0 }}
                                >
                                  <div className="d-flex flex-row align-items-center">
                                    <GreenCheckbox
                                      checked={this.state.covidChecked}
                                      onChange={this.handleCovidCheckbox}
                                      style={{ paddingLeft: 0 }}
                                    />

                                    <Typography
                                      variant="body1"
                                      style={{ fontSize: 14, margin: 0 }}
                                    >
                                      Eu estou ciente das exigências para entrar
                                      no Marrocos (esquema vacinal completo +
                                      passaporte com validade superior a 6 meses
                                      do dia de chegada ao Marrocos).
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <div
                                  className="col-10 my-2"
                                  style={{ padding: 0 }}
                                >
                                  <div className="d-flex flex-row align-items-center">
                                    <GreenCheckbox
                                      checked={this.state.rulesChecked}
                                      onChange={this.handleRulesChecked}
                                      style={{ paddingLeft: 0 }}
                                    />

                                    <Typography
                                      variant="body1"
                                      style={{ fontSize: 14, margin: 0 }}
                                    >
                                      Li e aceito o{' '}
                                      <a
                                        target="_blank"
                                        href={rulesUrl}
                                        rel="noopener noreferrer"
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          style={{
                                            fontSize: '0.875rem',
                                            display: 'inline-block',
                                            textDecorationLine: 'underline',
                                          }}
                                        >
                                          regulamento{' '}
                                        </Typography>
                                      </a>{' '}
                                      da promoção.
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className="container">
                          <div className="row justify-content-center">
                            <div className="col-10 my-2" style={{ padding: 0 }}>
                              <div className="d-flex flex-row justify-content-center align-items-center">
                                <GreenCheckbox
                                  checked={this.state.checked}
                                  onChange={this.handleCheckBox}
                                  style={{ paddingLeft: 0 }}
                                />

                                <Typography
                                  variant="body1"
                                  style={{ fontSize: 14, margin: 0 }}
                                >
                                  Li e aceito os{' '}
                                  <Typography
                                    variant="subtitle2"
                                    onClick={() =>
                                      IS_CAIXA
                                        ? window.open(
                                            'https://www.caixa.gov.br/termos-de-uso/Paginas/default.aspx',
                                            '_blank'
                                          )
                                        : this.setState({ useTerms: true })
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: 14,
                                    }}
                                  >
                                    termos de uso
                                  </Typography>{' '}
                                  e a{' '}
                                  <Typography
                                    variant="subtitle2"
                                    onClick={() =>
                                      IS_CAIXA
                                        ? window.open(
                                            'https://www.caixa.gov.br/privacidade/aviso-de-privacidade/Paginas/default.aspx',
                                            '_blank'
                                          )
                                        : this.setState({ privacy: true })
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: 14,
                                    }}
                                  >
                                    política de privacidade
                                  </Typography>
                                  .
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>

                        {IS_BRB_FLA && (
                          <div mt={3}>
                            <div className="row justify-content-center my-3">
                              <div className="col-12">
                                <div className="d-flex justify-content-center">
                                  <Field
                                    component={CaptchaInput}
                                    inputlabelprops={{
                                      shrink: true,
                                    }}
                                    name="captcha"
                                    validate={[FORM_RULES.required]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row justify-content-center  my-4">
                          <div className="col-10">
                            <div className="d-flex justify-content-center">
                              <FullButton
                                classNameProp="py-3 px-4"
                                type="submit"
                                onClick={this.handleClick}
                                disabled={
                                  this.props.auth.loadingRegister ||
                                  !this.state.checked ||
                                  ((!this.state.covidChecked ||
                                    !this.state.rulesChecked) &&
                                    IS_BRB_FLA) ||
                                  (this.props.formValues
                                    ? !this.props.formValues.email &&
                                      COMPANY_STATION_ID !==
                                        SINDILEGIS_STATION_ID &&
                                      COMPANY_STATION_ID !== ASTCU_STATION_ID
                                    : true) ||
                                  ((!this.props.website.stationData.public &&
                                    !IS_CAIXA) ||
                                  COMPANY_STATION_ID ===
                                    SINDILEGIS_STATION_ID ||
                                  COMPANY_STATION_ID === ASTCU_STATION_ID
                                    ? this.props.formValues
                                      ? !this.props.formValues.cpf
                                      : true
                                    : false)
                                }
                                progress={parseInt(
                                  this.props.general.submitProgress
                                )}
                              >
                                Continuar
                              </FullButton>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="container my-3 pb-4">
                  <div className="row justify-content-center">
                    <div
                      className="col-10 d-flex flex-column justify-content-center align-items-center p-3"
                      style={{
                        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          fontSize: '0.875rem',
                          marginBottom: 0,
                        }}
                      >
                        Já possui acesso?{' '}
                        <a href="#/login">
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontSize: '0.875rem',
                              display: 'inline-block',
                              textDecorationLine: 'underline',
                            }}
                          >
                            Entre
                          </Typography>
                        </a>
                      </Typography>{' '}
                      {IS_BRB_FLA && (
                        <a
                          target="_blank"
                          href={rulesUrl}
                          rel="noopener noreferrer"
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontSize: '0.875rem',
                              display: 'inline-block',
                              textDecorationLine: 'underline',
                            }}
                          >
                            Regulamento{' '}
                          </Typography>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.stepForm === 1 ? (
              <PassStepForm
                history={this.props.history}
                setStepForm={this.setStepForm}
                affiliatedInfo={this.state.affiliatedInfo}
                setAffiliatedInfo={this.setAffiliatedInfo}
                match={this.props.match}
              />
            ) : (
              <div className="pb-5">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="row justify-content-center my-2">
                      <div className="col-10">
                        <p className="h5 text-center">
                          Selecione um método para receber o código de
                          verificação de identidade
                        </p>
                        {this.state.affiliatedInfo.tel ? (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            px={3}
                            alignItems="center"
                            style={{
                              backgroundColor: '#F2F2F2',
                              cursor: 'pointer',
                            }}
                            mt={3}
                            py={3}
                            borderRadius={5}
                            onClick={() => this.selectConfirmationMethod('sms')}
                          >
                            <p
                              className="h5"
                              style={{ color: COMPANY_COLORS.general.text }}
                            >
                              {this.renderHiddenPhone(
                                this.state.affiliatedInfo.tel
                              )}
                            </p>
                            <ChevronRightIcon />
                          </Box>
                        ) : null}

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          px={3}
                          alignItems="center"
                          style={{
                            backgroundColor: '#F2F2F2',
                            cursor: 'pointer',
                          }}
                          mt={1}
                          py={3}
                          borderRadius={5}
                          onClick={() => this.selectConfirmationMethod('email')}
                        >
                          <p
                            className="h5"
                            style={{ color: COMPANY_COLORS.general.text }}
                          >
                            {this.renderHiddenEmail(
                              this.state.affiliatedInfo.email
                            )}
                          </p>
                          <ChevronRightIcon />
                        </Box>
                        {IS_SINDILEGIS || IS_ASTCU ? (
                          <div className="d-flex justify-content-center">
                            <p
                              className="h5 text-center mt-4"
                              style={{ maxWidth: 370 }}
                            >
                              Não tem acesso aos contatos acima? Ligue para (61)
                              3214-7322
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <AlertDialogSlide
            onClose={() => this.setState({ privacy: false })}
            visible={this.state.privacy}
            titleBackground={'#FFFF'}
            backgroundColor={'#FFFF'}
          >
            <PrivacyPolicy />
          </AlertDialogSlide>
          <AlertDialogSlide
            onClose={() => this.setState({ useTerms: false })}
            visible={this.state.useTerms}
            titleBackground={'#FFFF'}
            backgroundColor={'#FFFF'}
          >
            <UseTerms />
          </AlertDialogSlide>
        </div>
      </div>
    );
  }
}

SignupStepForm = reduxForm({ form: 'signupStepForm' })(SignupStepForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('signupStepForm')(state),
    website: state.website,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      signupAdmin,
      showModal,
      participantCredentialsValidations,
      validateMGM,
      getStationData,
      selectConfirmationMethod,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupStepForm);
