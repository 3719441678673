/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid } from '@material-ui/core';
import { DefaultCard } from 'common/components/cards/DefaultCard';
import { BASE_S3 } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CarouselSkeleton from '../../../../../common/components/skeleton/carouselSkeleton';
import { getListByUserId, setLoading } from './productOrdersActions';

const heightAndBackground = {
  minHeight: '10vh',
  backgroundColor: 'transparent',
};

const cursorPointer = { cursor: 'pointer' };

const generic6ElementsArray = Array.from(new Array(6));

export function ProductOrders(props) {
  useEffect(() => {
    props.getListByUserId(props.auth.user.id);
  }, []);

  function selectItem(item) {
    props.history.push(`/homepage/pedidos/${item.id}`);
  }

  if (props.productOrders.loading) {
    return (
      <>
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          xs={12}
          spacing={2}
          style={heightAndBackground}
        >
          {generic6ElementsArray.map((el, index) => (
            <Grid
              key={index}
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={12}
            >
              <CarouselSkeleton elementsHeight={150} />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  if (_.isEmpty(props.productOrders.list.content)) {
    return (
      <section id="ProductOrders-list">
         <div
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: 'transparent',
            height: '248px',
          }}
        >
        <Typography variant="h5">Meus pedidos</Typography>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={heightAndBackground}
        >
          <Typography variant="h6">Você ainda não fez pedidos</Typography>
        </div>
      </section>
    );
  }

  return (
    <>
      <section id="ProductOrders-list" style={heightAndBackground}>
        <Typography variant="h5">Meus pedidos</Typography>
        <div className="container-fluid px-4 pt-4">
          <div className="row">
            {props.productOrders.list.content.map((item, index) => {
              return (
                <div
                  key={index}
                  className="mb-4 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                  style={cursorPointer}
                  onClick={() => selectItem(item)}
                >
                  <DefaultCard
                    imgSource={`${BASE_S3}/${item.action.image}`}
                    labelClick="Ver Pedido"
                    actionName={moment(item.createdAt).format('LLLL')}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    productOrders: state.productOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByUserId,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductOrders);
