import React, { useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  IS_CAIXA_MODERADOR,
} from '../../../../../../../config/consts';
import ChatIcon from '@material-ui/icons/Chat';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';

import { Container, Content, Interaction, Confirmation } from './styles';
import Like from '../like';
import Comments from '../comment';
import CustomizedModal from '../../../../../../../common/components/modal/materialModal';
import { deletePostWithId } from '../../FeedActions';
import { Box, Typography } from '@material-ui/core';
import { getNameInitials } from 'helpers/utils';

function Post(props) {
  const [commentVisible, setCommentVisible] = useState(false);
  const [open, setOpen] = useState(false);

  if (!props.info) {
    return <p>Carregando...</p>;
  }

  async function handleDelete() {
    await props.deletePostWithId(
      props.info.id,
      props.auth.user.station_id,
      props.limit
    );
    setOpen(false);
  }

  return (
    <>
      <Container>
        <header>
          <section>
            {props.info.user?.image ? (
              <img
                src={`${BASE_S3}/${props.info.user?.image}`}
                alt="Imagem de perfil"
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  backgroundColor: BUTTON_BACKGROUND_COLOR,
                  height: 50,
                  width: 50,
                  borderRadius: '50%',
                  fontSize: 25,
                  color: BUTTON_TEXT_COLOR,
                  fontFamily: BUTTON_FONT_FAMILY,
                }}
              >
                {getNameInitials(props.info.user?.name)}
              </Box>
            )}
            <div>
              <Typography variant="body1">
                {props.info.user?.name.split(' ')[0]}
              </Typography>
              <Typography variant="body1">
                {moment(props.info.createdAt).fromNow()}
              </Typography>
            </div>
          </section>
          {(props.info.userAuthor ||
            IS_CAIXA_MODERADOR(props.auth.user.id)) && (
            <DeleteIcon
              style={{ color: BUTTON_BACKGROUND_COLOR }}
              onClick={() => setOpen(true)}
            />
          )}
        </header>
        <Content>
          <Typography variant="body1">{props.info.description}</Typography>
          {props.info.media_type === 'image' && (
            <img src={`${BASE_S3}/${props.info.media}`} alt="foto" />
          )}
          {props.info.media_type === 'video' && (
            <iframe
              src={`https://player.vimeo.com/video/${
                props.info.media.split('/')[2]
              }`}
              style={{ width: '100%', height: '450px' }}
              frameborder="0"
              title="vimeo video"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            />
          )}
        </Content>
        <Interaction>
          <Like
            likes={props.info.likes}
            userLiked={props.info.userLiked}
            postId={props.info.id}
            limit={props.limit}
          />
          <button
            type="button"
            onClick={() => setCommentVisible(!commentVisible)}
          >
            <ChatIcon />
            <Typography variant="body1">
              {props.info.comments.length}
            </Typography>
          </button>
        </Interaction>
        <Comments postId={props.info.id} visible={commentVisible} />
      </Container>
      <CustomizedModal fullWidth maxWidth="xs" open={open}>
        <Confirmation color={COMPANY_COLORS.primary}>
          <strong>Você vai deletar esse post</strong>
          <p>Tem certeza?</p>
          <div>
            <button type="button" onClick={() => setOpen(false)}>
              Cancelar
            </button>
            <button type="button" onClick={() => handleDelete()}>
              Sim
            </button>
          </div>
        </Confirmation>
      </CustomizedModal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ deletePostWithId }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Post);
