import {
  BASE_S3,
  BRB_FLA_STATION_ID,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
} from 'config/consts';
import { getStationData } from 'modules/website/websiteActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingProgress from '../../../common/components/progress/loading';
import { setLoading, signup } from '../authActions';
import PrivacyPolicyText from './privacyPolicyText';
import BrbFlaUsePolicyText from './nacaoBrbFla/brbFlaPrivacyPolicyText';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    props.setLoading(false);
    props.getStationData(COMPANY_STATION_ID);
  }

  render() {
    const { website } = COMPANY_COLORS;

    return (
      <div className="col-12" style={{ backgroundColor: '#FFFF' }}>
        <div style={{ background: '#ffffff', borderRadius: 4 }}>
          <div className="row justify-content-center">
            <div className="col-7 d-flex justify-content-center py-3">
              <img
                // className
                src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                alt="Logo"
                style={{ maxWidth: '100%', height: 'auto', maxHeight: 85 }}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center my-2">
                <div className="col-8">
                  <span
                    className="station-font-medium text-center"
                    style={{ color: COMPANY_COLORS.general.text }}
                  >
                    {this.props.website.stationDataLoading ? (
                      <LoadingProgress />
                    ) : // COMPANY_STATION_ID === 1 ? (
                    COMPANY_STATION_ID === BRB_FLA_STATION_ID ? (
                      <>
                        <BrbFlaUsePolicyText />
                      </>
                    ) : (
                      <PrivacyPolicyText
                        stationName={this.props.website.stationData.name}
                        stationContact={
                          this.props.website.stationData.contact_mail
                        }
                        stationPhysicalAddress={
                          this.props.website.stationData.address
                        }
                        siteAddress={this.props.website.stationData.url}
                        address={
                          this.props.website.stationData.establishment.address
                        }
                        cnpj={this.props.website.stationData.establishment.cnpj}
                        socialName={
                          this.props.website.stationData.establishment
                            .full_social_name
                        }
                        createdAt={this.props.website.stationData.createdAt}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-10">
              <div
                style={{
                  background: 'lightgray',
                  height: 1,
                  width: '100%',
                  opacity: 0.5,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      getStationData,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
