import moment from 'moment';
import React, { Component } from 'react';
import './privacyPolicyStyle.css';

const styles = {
  container: {
    flex: 1,
    paddingTop: 30,
    backgroundColor: '#fff',
    marginBottom: 16,
  },
  head: { height: 60, backgroundColor: '#fff' },
  text: { marginVertical: 8, textAlign: 'left', fontWeight: '300' },
  bulletText: {
    marginVertical: 8,
    paddingLeft: 16,
    textAlign: 'left',
    fontWeight: '300',
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    marginVertical: 8,
    textAlign: 'left',
  },
  subtitle: {
    fontSize: 20,
    textAlign: 'left',
    fontWeight: '500',
  },
};

export default class UsePolicyText extends Component {
  render() {
    const {
      socialName,
      stationName,
      createdAt,
      stationPhysicalAddress,
      stationContact,
    } = this.props;
    return (
      <div
        style={{
          flex: 1,
          paddingHorizontal: 16,
          color: '#000',
          backgroundColor: '#FFFF',
        }}
        className="privacy-policy-text"
      >
        <p style={styles.title}>Política de Privacidade</p>
        <p style={styles.text}>
          {stationName} é um programa de relacionamento mantido e gerenciado por{' '}
          {socialName}.
        </p>

        <p style={styles.subtitle}>INFORMAÇÕES GERAIS</p>

        <p style={styles.text}>
          O presente documento contém informações a respeito do modo como este
          programa de relacionamento trata, total ou parcialmente, de forma
          automatizada ou não, os dados pessoais dos usuários que acessam o
          programa. Seu objetivo é esclarecer os interessados acerca dos tipos
          de dados que são coletados, dos motivos da coleta e da forma como o
          usuário poderá atualizar, gerenciar ou excluir estas informações.
        </p>
        <p style={styles.text}>
          Este documento foi elaborado em conformidade com a Lei Federal n.
          12.965, de 23 de abril de 2014 (Marco Civil da Internet), com a Lei
          Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados
          Pessoais) e com o Regulamento UE n. 2016/679, de 27 de abril de 2016
          (Regulamento Geral Europeu de Proteção de Dados Pessoais – RGDP)..
        </p>
        <p style={styles.text}>
          Ademais, esclarece-se que a presente poderá ser atualizada em
          decorrência de eventual evolução normativa, razão pela qual se convida
          o usuário a consultar periodicamente esta seção.
        </p>
        <p style={styles.subtitle}>DIREITOS DO USUÁRIO</p>
        <p style={styles.text}>
          O programa se compromete a cumprir as normas previstas no RGPD, em
          respeito aos seguintes princípios:
        </p>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Os dados pessoais do usuário serão processados de forma
            lícita, leal e transparente (licitude, lealdade e transparência);
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Os dados pessoais do usuário serão coletados apenas para
            finalidades determinadas, explícitas e legítimas, não podendo ser
            tratados posteriormente de uma forma incompatível com essas
            finalidades (limitação das finalidades);
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Os dados pessoais do usuário serão coletados de forma
            adequada, pertinente e limitada às necessidades do objetivo para os
            quais eles são processados (minimização dos dados);
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Os dados pessoais do usuário serão exatos e atualizados
            sempre que necessário, de maneira que os dados inexatos sejam
            apagados ou retificados quando possível (exatidão);
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Os dados pessoais do usuário serão conservados de uma
            forma que permita a identificação dos titulares dos dados apenas
            durante o período necessário para as finalidades para as quais são
            tratados (limitação da conservação);
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Os dados pessoais do usuário serão tratados de forma
            segura, protegidos do tratamento não autorizado ou ilícito e contra
            a sua perda, destruição ou danificação acidental, adotando as
            medidas técnicas ou organizativas adequadas (integridade e
            confidencialidade).
          </p>
        </div>
        <p style={styles.text}>
          O usuário do aplicativo possui os seguintes direitos, conferidos pela
          Lei de Proteção de Dados Pessoais e pelo RGPD:
        </p>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Direito de confirmação e acesso: é o direito do usuário
            de obter do aplicativo a confirmação de que os dados pessoais que
            lhe digam respeito são ou não objeto de tratamento e, se for esse o
            caso, o direito de acessar os seus dados pessoais;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Direito de retificação: é o direito do usuário de obter
            do aplicativo, sem demora injustificada, a retificação dos dados
            pessoais inexatos que lhe digam respeito;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Direito à eliminação dos dados (direito ao esquecimento):
            é o direito do usuário de ter seus dados apagados do aplicativo;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Direito à limitação do tratamento dos dados: é o direito
            do usuário de limitar o tratamento de seus dados pessoais, podendo
            obtê-la quando contesta a exatidão dos dados, quando o tratamento
            for ilícito, quando o aplicativo não precisar mais dos dados para as
            finalidades propostas e quando tiver se oposto ao tratamento dos
            dados e em caso de tratamento de dados desnecessários;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Direito de oposição: é o direito do usuário de, a
            qualquer momento, se opor por motivos relacionados com a sua
            situação particular, ao tratamento dos dados pessoais que lhe digam
            respeito, podendo se opor ainda ao uso de seus dados pessoais para
            definição de perfil de marketing (profiling);
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Direito de portabilidade dos dados: é o direito do
            usuário de receber os dados pessoais que lhe digam respeito e que
            tenha fornecido ao aplicativo, num formato estruturado, de uso
            corrente e de leitura automática, e o direito de transmitir esses
            dados a outro aplicativo;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Direito de não ser submetido a decisões automatizadas: é
            o direito do usuário de não ficar sujeito a nenhuma decisão tomada
            exclusivamente com base no tratamento automatizado, incluindo a
            definição de perfis (profiling), que produza efeitos na sua esfera
            jurídica ou que o afete significativamente de forma similar.
          </p>
        </div>
        <p style={styles.text}>
          O usuário poderá exercer os seus direitos por meio de comunicação
          escrita enviada ao aplicativo com o assunto “RGDP”, especificando:
        </p>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Nome completo ou razão social, número do CPF (Cadastro de
            Pessoas Físicas, da Receita Federal do Brasil) ou CNPJ (Cadastro
            Nacional de Pessoa Jurídica, da Receita Federal do Brasil) e
            endereço de e-mail do usuário e, se for o caso, do seu
            representante;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Direito que deseja exercer junto ao aplicativo;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Data do pedido e assinatura do usuário;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Todo documento que possa demonstrar ou justificar o
            exercício de seu direito.
          </p>
        </div>
        <p style={styles.text}>
          O pedido deverá ser enviado ao e-mail: {stationContact}, ou por
          correio, ao seguinte endereço:
        </p>
        <p>{stationPhysicalAddress}</p>
        <p style={styles.text}>
          O usuário será informado em caso de retificação ou eliminação dos seus
          dados.
        </p>

        <p style={styles.subtitle}>DEVER DE NÃO FORNECER DADOS DE TERCEIROS</p>
        <p style={styles.text}>
          Durante a utilização do site, a fim de resguardar e de proteger os
          direitos de terceiros, o usuário do aplicativo deverá fornecer somente
          seus dados pessoais, e não os de terceiros
        </p>
        <p style={styles.subtitle}>INFORMAÇÕES COLETADAS</p>
        <p style={styles.text}>
          A coleta de dados dos usuários se dará em conformidade com o disposto
          nesta Política de Privacidade e dependerá do consentimento do usuário,
          sendo este dispensável somente nas hipóteses previstas no art. 11,
          inciso II, da Lei de Proteção de Dados Pessoais.
        </p>
        <p style={styles.subtitle}>TIPOS DE DADOS COLETADOS</p>
        <p style={styles.subtitle}>
          DADOS DE IDENTIFICAÇÃO DO USUÁRIO PARA REALIZAÇÃO DE CADASTRO
        </p>
        <p style={styles.text}>
          A utilização, pelo usuário, de determinadas funcionalidades do
          aplicativo dependerá de cadastro, sendo que, nestes casos, os
          seguintes dados do usuário serão coletados e armazenados:
        </p>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>{'\u2022'} Nome;</p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>{'\u2022'} Data de nascimento;</p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>{'\u2022'} Endereço de e-mail;</p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>{'\u2022'} UF de residência;</p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>{'\u2022'} Número de telefone;</p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>{'\u2022'} Número de CPF; e</p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Dados relativos à ocupação do usuário, tais como
            profissão, grau de escolaridade e experiência profissional.
          </p>
        </div>

        <p style={styles.subtitle}>DADOS INFORMADOS NO FORMULÁRIO DE CONTATO</p>
        <p style={styles.text}>
          Os dados eventualmente informados pelo usuário que utilizar o
          formulário de contato disponibilizado no aplicativo, incluindo o teor
          da mensagem enviada, serão coletados e armazenados.
        </p>

        <p style={styles.subtitle}>
          DADOS RELACIONADOS À EXECUÇÃO DE CONTRATOS FIRMADOS COM O USUÁRIO
        </p>
        <p style={styles.text}>
          Para a execução de contrato de compra e venda ou de prestação de
          serviços eventualmente firmado entre o aplicativo e o usuário, poderão
          ser coletados e armazenados outros dados relacionados ou necessários a
          sua execução, incluindo o teor de eventuais comunicações tidas com o
          usuário.
        </p>

        <p style={styles.subtitle}>REGISTROS DE ACESSO</p>
        <p style={styles.text}>
          Em atendimento às disposições do art. 15, caput e parágrafos, da Lei
          Federal n. 12.965/2014 (Marco Civil da Internet), os registros de
          acesso do usuário serão coletados e armazenados por, pelo menos, seis
          meses.
        </p>

        <p style={styles.subtitle}>DADOS SENSÍVEIS</p>
        <p style={styles.text}>
          O aplicativo poderá coletar os seguintes dados sensíveis dos usuários:
        </p>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>{'\u2022'} Dados bancários.</p>
        </div>
        <p style={styles.subtitle}>
          FUNDAMENTO JURÍDICO PARA O TRATAMENTO DOS DADOS PESSOAIS
        </p>
        <p style={styles.text}>
          Ao utilizar os serviços do aplicativo, o usuário está consentindo com
          a presente Política de Privacidade.
        </p>
        <p style={styles.text}>
          O usuário tem o direito de retirar seu consentimento a qualquer
          momento, não comprometendo a licitude do tratamento de seus dados
          pessoais antes da retirada. A retirada do consentimento poderá ser
          feita pelo e-mail: {stationContact}, ou por correio enviado ao
          seguinte endereço:
        </p>
        <p>{stationPhysicalAddress}</p>
        <p style={styles.text}>
          O consentimento dos relativamente ou absolutamente incapazes,
          especialmente de crianças menores de 16 (dezesseis) anos, apenas
          poderá ser feito, respectivamente, se devidamente assistidos ou
          representados.
        </p>
        <p style={styles.text}>
          Poderão ainda ser coletados dados pessoais necessários para a execução
          e cumprimento dos serviços contratados pelo usuário no aplicativo.
        </p>
        <p style={styles.text}>
          O tratamento de dados pessoais sem o consentimento do usuário apenas
          será realizado em razão de interesse legítimo ou para as hipóteses
          previstas em lei, ou seja, dentre outras, as seguintes:
        </p>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para o cumprimento de obrigação legal ou regulatória pelo
            controlador;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para a realização de estudos por órgão de pesquisa,
            garantida, sempre que possível, a anonimização dos dados pessoais;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Quando necessário para a execução de contrato ou de
            procedimentos preliminares relacionados a contrato do qual seja
            parte o usuário, a pedido do titular dos dados;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para o exercício regular de direitos em processo
            judicial, administrativo ou arbitral, esse último nos termos da Lei
            nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para a proteção da vida ou da incolumidade física do
            titular dos dados ou de terceiro;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para a tutela da saúde, em procedimento realizado por
            profissionais da área da saúde ou por entidades sanitárias;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Quando necessário para atender aos interesses legítimos
            do controlador ou de terceiro, exceto no caso de prevalecerem
            direitos e liberdades fundamentais do titular dos dados que exijam a
            proteção dos dados pessoais;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para a proteção do crédito, inclusive quanto ao disposto
            na legislação pertinente.
          </p>
        </div>
        <p style={styles.subtitle}>
          FINALIDADES DO TRATAMENTO DOS DADOS PESSOAIS
        </p>
        <p style={styles.text}>
          Os dados pessoais do usuário coletados pelo aplicativo têm por
          finalidade facilitar, agilizar e cumprir os compromissos estabelecidos
          com o usuário e a fazer cumprir as solicitações realizadas por meio do
          preenchimento de formulários.
        </p>
        <p style={styles.text}>
          Os dados pessoais poderão ser utilizados também com uma finalidade
          comercial, para personalizar o conteúdo oferecido ao usuário, bem como
          para dar subsídio ao aplicativo para a melhora da qualidade e
          funcionamento de seus serviços.
        </p>
        <p style={styles.text}>
          O aplicativo recolhe os dados do usuário para que seja realizada
          definição de perfis (profiling), ou seja, tratamento automatizado de
          dados pessoais que consista em utilizar estes dados para avaliar
          certos aspectos pessoais do usuário, principalmente para analisar ou
          prever características relacionadas ao seu desempenho profissional, a
          sua situação econômica, saúde, preferências pessoais, interesses,
          fiabilidade, comportamento, localização ou deslocamento.
        </p>
        <p style={styles.text}>
          Os dados de cadastro serão utilizados para permitir o acesso do
          usuário a determinados conteúdos do aplicativo, exclusivos para
          usuários cadastrados.
        </p>
        <p style={styles.text}>
          A coleta de dados relacionados ou necessários à execução de um
          contrato de compra e venda ou de prestação de serviços eventualmente
          firmado com o usuário terá a finalidade de conferir às partes
          segurança jurídica, além de facilitar e viabilizar a conclusão do
          negócio.
        </p>
        <p style={styles.text}>
          O tratamento de dados pessoais para finalidades não previstas nesta
          Política de Privacidade somente ocorrerá mediante comunicação prévia
          ao usuário, sendo que, em qualquer caso, os direitos e obrigações aqui
          previstos permanecerão aplicáveis.
        </p>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para o cumprimento de obrigação legal ou regulatória pelo
            controlador;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para estudo por órgão de pesquisa, garantida, sempre que
            possível, a anonimização dos dados pessoais;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para a transferência a terceiro, desde que respeitados os
            requisitos de tratamento de dados dispostos na legislação;
          </p>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <p style={styles.bulletText}>
            {'\u2022'} Para uso exclusivo do controlador, vedado seu acesso por
            terceiro, e desde que anonimizados os dados.
          </p>
        </div>
        <p style={styles.subtitle}>
          DESTINATÁRIOS E TRANSFERÊNCIA DOS DADOS PESSOAIS
        </p>
        <p style={styles.text}>
          Os dados pessoais poderão ser compartilhados com parceiros do
          aplicativo, sob anuência do usuário.
        </p>

        <p style={styles.subtitle}>DO TRATAMENTO DOS DADOS PESSOAIS</p>
        <p style={styles.subtitle}>
          DO RESPONSÁVEL PELO TRATAMENTO DOS DADOS (DATA CONTROLLER)
        </p>
        <p style={styles.text}>
          O controlador, responsável pelo tratamento dos dados pessoais do
          usuário, é a pessoa física ou jurídica, a autoridade pública, a
          agência ou outro organismo que, individualmente ou em conjunto com
          outras, determina as finalidades e os meios de tratamento de dados
          pessoais.
        </p>
        <p style={styles.text}>
          Neste aplicativo, o responsável pelo tratamento dos dados pessoais
          coletados poderá ser contactado pelo e-mail: {stationContact} ou
          presencialmente no endereço:
        </p>
        <p style={styles.text}>{stationPhysicalAddress}</p>
        <p style={styles.text}>
          O responsável pelo tratamento dos dados se encarregará diretamente do
          tratamento dos dados pessoais do usuário.
        </p>

        <p style={styles.subtitle}>
          DO ENCARREGADO DE PROTEÇÃO DE DADOS (DATA PROTECTION OFFICER)
        </p>
        <p style={styles.text}>
          O encarregado de proteção de dados (data protection officer) é o
          profissional encarregado de informar, aconselhar e controlar o
          responsável pelo tratamento dos dados, bem como os trabalhadores que
          tratem os dados, a respeito das obrigações do aplicativo nos termos do
          RGDP, da Lei de Proteção de Dados Pessoais e de outras disposições de
          proteção de dados presentes na legislação nacional e internacional, em
          cooperação com a autoridade de controle competente.
        </p>
        <p style={styles.text}>
          Neste aplicativo o encarregado de proteção de dados (data protection
          officer) poderá ser contatado pelo e-mail: {stationContact}.
        </p>
        <p style={styles.subtitle}>
          SEGURANÇA NO TRATAMENTO DOS DADOS PESSOAIS DO USUÁRIO
        </p>
        <p style={styles.text}>
          O aplicativo se compromete a aplicar as medidas técnicas e
          organizativas aptas a proteger os dados pessoais de acessos não
          autorizados e de situações de destruição, perda, alteração,
          comunicação ou difusão de tais dados.
        </p>
        <p style={styles.text}>
          Para a garantia da segurança, serão adotadas soluções que levem em
          consideração: as técnicas adequadas; os custos de aplicação; a
          natureza, o âmbito, o contexto e as finalidades do tratamento; e os
          riscos para os direitos e liberdades do usuário.
        </p>
        <p style={styles.text}>
          O aplicativo utiliza certificado SSL (Secure Socket Layer) que garante
          que os dados pessoais se transmitam de forma segura e confidencial, de
          maneira que a transmissão dos dados entre o servidor e o usuário, e em
          retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.
        </p>
        <p style={styles.text}>
          Não está autorizado qualquer tipo de compartilhamento dos dados
          pessoais do usuário sem prévia aprovação do usuário, pelo gestor do
          aplicativo, {stationName}, em desconformidade com as normas que regem
          proteção de dados pessoais.
        </p>
        <p style={styles.text}>
          No entanto, o aplicativo se exime de responsabilidade por culpa
          exclusiva de terceiros, como em caso de ataque de hackers ou crackers,
          ou culpa exclusiva do gestor do aplicativo ou do usuário. O aplicativo
          se compromete, ainda, a comunicar o usuário em prazo adequado caso
          ocorra algum tipo de violação da segurança de seus dados pessoais que
          possa lhe causar um alto risco para seus direitos e liberdades
          pessoais.
        </p>
        <p style={styles.text}>
          A violação de dados pessoais é uma violação de segurança que provoque,
          de modo acidental ou ilícito, a destruição, a perda, a alteração, a
          divulgação ou o acesso não autorizado a dados pessoais transmitidos,
          conservados ou sujeitos a qualquer outro tipo de tratamento.
        </p>
        <p style={styles.text}>
          Por fim, o aplicativo se compromete a tratar os dados pessoais do
          usuário com confidencialidade, dentro dos limites legais.
        </p>
        <p style={styles.subtitle}>DADOS DE NAVEGAÇÃO (COOKIES)</p>
        <p style={styles.text}>
          Cookies são pequenos arquivos de texto enviados pelo aplicativo ao
          computador do usuário e que nele ficam armazenados, com informações
          relacionadas à navegação do aplicativo.
        </p>
        <p style={styles.text}>
          Por meio dos cookies, pequenas quantidades de informação são
          armazenadas pelo navegador do usuário para que nosso servidor possa
          lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre
          o dispositivo utilizado pelo usuário, bem como seu local e horário de
          acesso ao aplicativo.
        </p>
        <p style={styles.text}>
          Os cookies não permitem que qualquer arquivo ou informação sejam
          extraídos do disco rígido do usuário, não sendo possível, ainda, que,
          por meio deles, se tenha acesso a informações pessoais que não tenham
          partido do usuário ou da forma como utiliza os recursos do aplicativo.
        </p>
        <p style={styles.text}>
          É importante ressaltar que nem todo cookie contém informações que
          permitem a identificação do usuário, sendo que determinados tipos de
          cookies podem ser empregados simplesmente para que o aplicativo seja
          carregado corretamente ou para que suas funcionalidades funcionem do
          modo esperado.
        </p>
        <p style={styles.text}>
          As informações eventualmente armazenadas em cookies que permitam
          identificar um usuário são consideradas dados pessoais. Dessa forma,
          todas as regras previstas nesta Política de Privacidade também lhes
          são aplicáveis.
        </p>

        <p style={styles.subtitle}>COOKIES DE TERCEIROS</p>
        <p style={styles.text}>
          Alguns de nossos parceiros podem configurar cookies nos dispositivos
          dos usuários que acessam nosso aplicativo.
        </p>
        <p style={styles.text}>
          Estes cookies, em geral, visam possibilitar que nossos parceiros
          possam oferecer seu conteúdo e seus serviços ao usuário que acessa
          nosso aplicativo de forma personalizada, por meio da obtenção de dados
          de navegação extraídos a partir de sua interação com o aplicativo.
        </p>
        <p style={styles.text}>
          O usuário poderá obter mais informações sobre os cookies de terceiro e
          sobre a forma como os dados obtidos a partir dele são tratados, além
          de ter acesso à descrição dos cookies utilizados e de suas
          características, acessando o seguinte link:
        </p>
        <a
          href="https://analytics.google.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://analytics.google.com/
        </a>
        <a
          href="https://www.facebook.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/
        </a>
        <a
          href="https://www.hotjar.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.hotjar.com/
        </a>

        <p style={styles.text}>
          As entidades encarregadas da coleta dos cookies poderão ceder as
          informações obtidas a terceiros.
        </p>
        <p style={styles.subtitle}>COOKIES DE REDES SOCIAIS</p>
        <p style={styles.text}>
          O aplicativo utiliza plugins de redes sociais, que permitem acessá-las
          a partir do aplicativo. Assim, ao fazê-lo, os cookies utilizados por
          elas poderão ser armazenados no navegador do usuário.
        </p>
        <p style={styles.text}>
          Cada rede social possui sua própria política de privacidade e de
          proteção de dados pessoais, sendo as pessoas físicas ou jurídicas que
          as mantêm responsáveis pelos dados coletados e pelas práticas de
          privacidade adotadas.
        </p>
        <p style={styles.text}>
          O usuário pode pesquisar, junto às redes sociais, informações sobre
          como seus dados pessoais são tratados. A título informativo,
          disponibilizamos os seguintes links, a partir dos quais poderão ser
          consultadas as políticas de privacidade e de cookies adotadas por
          algumas das principais redes sociais:
        </p>
        <a
          href="https://www.facebook.com/policies/cookies/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook: https://www.facebook.com/policies/cookies/
        </a>
        <a
          href="https://twitter.com/pt/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter: https://twitter.com/pt/privacy
        </a>
        <a
          href="https://help.instagram.com/1896641480634370"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram: https://help.instagram.com/1896641480634370
        </a>
        <a
          href="https://policies.google.com/privacy?hl=pt-BR&gl=pt"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube: https://policies.google.com/privacy?hl=pt-BR&gl=pt
        </a>
        <p style={styles.subtitle}>
          GESTÃO DOS COOKIES E CONFIGURAÇÕES DO NAVEGADOR
        </p>
        <p style={styles.text}>
          O usuário poderá se opor ao registro de cookies pelo aplicativo,
          bastando que desative esta opção no seu próprio navegador ou aparelho.
        </p>
        <p style={styles.text}>
          A desativação dos cookies, no entanto, pode afetar a disponibilidade
          de algumas ferramentas e funcionalidades do aplicativo, comprometendo
          seu correto e esperado funcionamento. Outra consequência possível é
          remoção das preferências do usuário que eventualmente tiverem sido
          salvas, prejudicando sua experiência.
        </p>
        <p style={styles.text}>
          A seguir, são disponibilizados alguns links para as páginas de ajuda e
          suporte dos navegadores mais utilizados, que poderão ser acessadas
          pelo usuário interessado em obter mais informações sobre a gestão de
          cookies em seu navegador:
        </p>
        <a
          href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-deletemanage-cookies"
          target="_blank"
          rel="noopener noreferrer"
        >
          Internet Explorer:
          https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-deletemanage-cookies
        </a>
        <a
          href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
          target="_blank"
          rel="noopener noreferrer"
        >
          Safari: https://support.apple.com/pt-br/guide/safari/sfri11471/mac
        </a>
        <a
          href="https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chrome:
          https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt
        </a>
        <a
          href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mozila Firefox:
          https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
        </a>
        <a
          href="https://www.opera.com/help/tutorials/security/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Opera: https://www.opera.com/help/tutorials/security/privacy/
        </a>
        <p style={styles.subtitle}>RECLAMAÇÃO A UMA AUTORIDADE DE CONTROLE</p>
        <p style={styles.text}>
          Sem prejuízo de qualquer outra via de recurso administrativo ou
          judicial, todos os titulares de dados têm direito a apresentar
          reclamação a uma autoridade de controle.
        </p>
        <p style={styles.subtitle}>DAS ALTERAÇÕES</p>
        <p style={styles.text}>
          A presente versão desta Política de Privacidade foi atualizada pela
          última vez em:{' '}
          {moment(createdAt).format('dddd, D [de] MMMM [de] YYYY')}.
        </p>
        <p style={styles.text}>
          O editor se reserva o direito de modificar, a qualquer momento, o
          aplicativo, as presentes normas, especialmente para adaptá-las às
          evoluções do aplicativo, seja pela disponibilização de novas
          funcionalidades, seja pela supressão ou modificação daquelas já
          existentes.
        </p>
        <p style={styles.text}>
          O usuário será explicitamente notificado em caso de alteração desta
          política.
        </p>
        <p style={styles.text}>
          Ao utilizar o serviço após eventuais modificações, o usuário demonstra
          sua concordância com as novas normas. Caso discorde de alguma das
          modificações, deverá pedir, imediatamente, o cancelamento de sua conta
          e apresentar a sua ressalva ao serviço de atendimento, se assim o
          desejar.
        </p>
        <p style={styles.subtitle}>DO DIREITO APLICÁVEL E DO FORO</p>
        <p style={styles.text}>
          Para a solução das controvérsias decorrentes do presente instrumento,
          será aplicado integralmente o Direito brasileiro.
        </p>
        <p style={styles.text}>
          Os eventuais litígios deverão ser apresentados no foro da comarca em
          que se encontra a sede do editor do programa.
        </p>
      </div>
    );
  }
}
