import React, { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import NavBarBootstrap from 'common/components/navigation/navBarBootstrap';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_BORDER_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
  NAV_FONT_FAMILY,
  SIGNED_OFF_NAV_TEXT_COLOR,
} from '../../../../config/consts';
import { Grid, Menu, MenuItem, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { SignUpButton, NavigationButton } from './style';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    padding:
      props.mediaQueryOptions.xs || props.mediaQueryOptions.sm ? '' : '1rem',
    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: props.isMainPage
      ? 'transparent'
      : props.website?.aboutList?.content[0]?.background_color,
  }),
}));

const idAbastecimento =
  process.env.REACT_APP_API_KEY === 'test'
    ? 2
    : process.env.REACT_APP_API_KEY === 'production'
    ? 26
    : 1;

// When this component is called in any file !== website.jsx, isMainPage prop MUST be given as "false"
function NavigationBar(props) {
  const { website, history, isMainPage } = props;
  const { navbar } = COMPANY_COLORS.website;

  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const responsiveContainerMaxWidth = '1250px';
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const mediaQueryOptions = { xs, sm };
  const classes = useStyles({ mediaQueryOptions, website, isMainPage });

  function handleOpenMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(false);
  }

  function handleClickMenuItem() {
    handleCloseMenu();
    history.push('/');
  }

  const renderMenuItems = () =>
    website.aboutList.content.slice(0, 5).map((item, index) => (
      <NavigationButton
        key={item.menu_title}
        onClick={() => history.push('/')}
        href={item.type.id === 7 ? '#plans' : `#secao${item.id}`}
        color={SIGNED_OFF_NAV_TEXT_COLOR}
        fontSize={isTablet ? 14 : 16}
        style={{
          paddingRight: '1rem',
          fontFamily: NAV_FONT_FAMILY,
        }}
      >
        {item.menu_title}
      </NavigationButton>
    ));

  const renderGroupedMenuItems = () => (
    <>
      <NavigationButton
        fontSize={isTablet ? 14 : 16}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        color={SIGNED_OFF_NAV_TEXT_COLOR}
        style={{
          fontFamily: NAV_FONT_FAMILY,
        }}
      >
        Ver mais
        {/* <ArrowDropDownIcon /> */}
        <KeyboardArrowDownIcon />
      </NavigationButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        elevation={0}
        style={{
          position: 'fixed',
          boxSizing: 'border-box',
        }}
      >
        {website.aboutList.content.slice(5).map((item, index) => (
          <a
            key={item.menu_title}
            onClick={isMainPage ? null : () => history.push('/')}
            href={item.type.id === 7 ? '#plans' : `#secao${item.id}`}
            style={{
              textDecoration: 'none',
            }}
          >
            <MenuItem
              onClick={() => handleClickMenuItem()}
              style={{
                color: SIGNED_OFF_NAV_TEXT_COLOR,
                fontSize: isTablet ? 14 : 16,
                fontFamily: NAV_FONT_FAMILY,
              }}
              key={index} //bad key
            >
              {item.menu_title}
            </MenuItem>
          </a>
        ))}
      </Menu>
    </>
  );

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="center"
      xs={12}
      className={classes.root}
    >
      {sm || xs || isMobile ? (
        <div className="col-12">
          <NavBarBootstrap
            {...props}
            has_App={website.stationData.has_app}
            color={`transparent`}
          />
        </div>
      ) : (
        <Grid
          item
          container
          justifyContent="space-between"
          xs={12}
          style={{
            maxWidth: responsiveContainerMaxWidth,
          }}
        >
          <Grid item container alignItems="center" md={2}>
            <a href="/">
              <img
                src={`${BASE_S3}/${navbar.station_primary_logo}`}
                alt="LOGO_ESTAÇÃO"
                style={{
                  maxHeight: 60,
                }}
              />
            </a>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            md={7}
            xs={6}
          >
            {website.aboutList.content.length > 5 ? (
              <>
                {renderMenuItems()}
                {renderGroupedMenuItems()}
              </>
            ) : (
              renderMenuItems()
            )}
            <NavigationButton
              href="#suporte"
              onClick={() => history.push('/suporte')}
              color={SIGNED_OFF_NAV_TEXT_COLOR}
              fontSize={isTablet ? 14 : 16}
              style={{
                paddingRight: '1rem',
                fontFamily: NAV_FONT_FAMILY,
              }}
            >
              Fale Conosco
            </NavigationButton>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            xs={2}
            md={2}
            style={{
              minWidth: '259px',
            }}
          >
            {COMPANY_STATION_ID === parseInt(idAbastecimento) ? null : (
              <a
                href="#/login"
                style={{
                  color: SIGNED_OFF_NAV_TEXT_COLOR,
                  fontSize: isTablet ? 14 : 16,
                  marginRight: 24,
                  fontFamily: NAV_FONT_FAMILY,
                }}
              >
                Entrar
              </a>
            )}
            <SignUpButton
              onClick={() => history.push('/cadastrar')}
              background={BUTTON_BACKGROUND_COLOR}
              text={BUTTON_TEXT_COLOR}
              border={`1px solid ${BUTTON_BORDER_COLOR}`}
              style={{ fontFamily: BUTTON_FONT_FAMILY }}
            >
              Cadastre-se
            </SignUpButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

NavigationBar.defaultProps = {
  isMainPage: true,
};

NavigationBar.propTypes = {
  website: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isMainPage: PropTypes.bool.isRequired,
};

export default NavigationBar;
